import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Helmet from 'react-helmet'

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Helmet>
        <body id="kyoto-home" />
    </Helmet>
    <SEO
        title="Frisches und köstliches Sushi bestellen"
    />
    <section id="home" className="md:grid lg:grid-cols-2">
        <div className="md:hidden">
            <Link to="https://de.restaurantguru.com/Kyoto-Paderborn" target="_blank" class="p-2 bg-red-800 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                <span class="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs mr-3">New</span>
                <span class="mr-2 text-left flex-auto">Restaurant Guru 2023 - Das Beste Sushi in Paderborn</span>
                <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
            </Link>
        </div>
        <div className="md:grid md:grid-cols-2">
            <div className="md:mx-0 md:order-1">
                <StaticImage
                src="../images/kyoto-paderborn-tisch-reservieren.jpg"
                layout={'constrained'}
                quality={60}
                // width="480px"
                // height="650px"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tisch reservieren"
                className="block h-full w-full"
                />
            </div>
            <div className="text-center py-5 md:order-2 md:flex md:flex-col md:self-center md:p-5">
                <h3 className="text-2xl font-medium uppercase">Tisch reservieren</h3>
                <p className="my-4">Reservieren Sie einen Tisch</p>
                <a className="block w-10/12 mx-auto py-2 px-4 border border-red-500 text-red-500 text-xl hover:bg-red-500 hover:text-white" href="tel:+4952514145982">Anrufen</a>
            </div>
        </div>
        <div className="md:grid md:grid-cols-2">
            <div className="md:mx-0 md:order-4 lg:order-3">
                <StaticImage
                src="../images/kyoto_home-speisekarte.jpg"
                layout={'constrained'}
                quality={60}
                // width="480px"
                // height="650px"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tisch reservieren"
                className="block h-full w-full"
                />
            </div>
            <div className="text-center py-5 md:order-3 lg:order-4 md:flex md:flex-col md:self-center md:p-5">
                <h3 className="text-2xl font-medium uppercase">Speisekarte</h3>
                <p className="my-4">Leckeres Sushi in Paderborn</p>
                <Link className="block w-10/12 mx-auto py-2 px-4 border border-red-500 text-red-500 text-xl hover:bg-red-500 hover:text-white" to="/speisekarte">Durchblättern</Link>
            </div>
        </div>
        <div className="md:grid md:grid-cols-2">
            <div className="md:mx-0 md:order-5 lg:order-6">
                <StaticImage
                src="../images/kyoto_home-team.jpg"
                layout={'constrained'}
                quality={60}
                // width="480px"
                // height="650px"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tisch reservieren"
                className="block h-full w-full"
                />
            </div>
            <div className="text-center py-5 md:order-6 lg:order-5 md:flex md:flex-col md:self-center md:p-5">
                <h3 className="text-2xl font-medium uppercase">Über uns</h3>
                <p className="my-4">Erfahren Sie mehr über uns</p>
                <Link className="block w-10/12 mx-auto py-2 px-4 border border-red-500 text-red-500 text-xl hover:bg-red-500 hover:text-white" to="/team">Das Team</Link>
            </div>
        </div>
        <div className="md:grid md:grid-cols-2">
            <div className="md:mx-0 md:order-5 lg:order-6">
                <StaticImage
                src="../images/kyoto_home-kontakt.jpg"
                layout={'constrained'}
                quality={60}
                // width="480px"
                height="716px"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Kyoto Kontakt"
                className="block h-full w-full"
                />
            </div>
            <div className="text-center py-5 md:order-6 lg:order-5 md:flex md:flex-col md:self-center md:p-5">
                <h3 className="text-2xl font-medium uppercase">Kontakt</h3>
                <p className="my-4">Haben Sie noch Fragen?</p>
                <Link className="block w-10/12 mx-auto py-2 px-4 border border-red-500 text-red-500 text-xl hover:bg-red-500 hover:text-white" to="/kontakt">Kontakt</Link>
            </div>
        </div>
    </section>
  </Layout>
)

export default IndexPage
